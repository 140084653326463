<template>
  <div>
    <Menu />

    <section>
      <div class="container-fluid pt-3 g-0">
        <div class="row g-0">
          <div class="col-md-12 text-center">
            <div class="home-banner">
              <div class="col-lg-6 mx-auto align-self-center switch-white pb-5">
                <h1 class="display-2 mx-2"><strong>Chas Everitt - Luxury Winelands</strong> </h1>
                <h2 class="display-6 mx-2 pb-5">Refined living in the heart of the Cape Winelands</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid p-3 my-5">
        <div class="row g-0">
          <div class="col-md-12 text-center">
            <h2 class="pb-4"><strong>Find a Property</strong></h2>
            <button class="btn cta-btn m-1"><router-link to="/properties" @click.native="$store.state.searchState = 'Residential'; $store.state.filterType = 'Residential For Sale'; $store.state.filterProperty = 'House';" class="dropdown-item">Residential</router-link></button>
            <button class="btn cta-btn m-1"><router-link to="/developments" class="dropdown-item">New Developments</router-link></button>
            <button class="btn cta-btn m-1"><router-link to="/properties" @click.native="$store.state.searchState = 'Commercial'; $store.state.filterType = 'Commercial For Sale'; $store.state.filterProperty = 'Office';" class="dropdown-item">Commercial & Industrial</router-link></button>
            <button class="btn cta-btn m-1"><router-link to="/properties" @click.native="$store.state.searchState = 'Farms'; $store.state.filterType = 'Commercial For Sale'; $store.state.filterProperty = 'Farm';" class="dropdown-item">Farms & Smallholdings</router-link></button>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid vh-75">
        <div class="row">
          <div class="col-md-12">
            <div 
              class="switch-bg-lblue p-5 h-100 video-container" 
              @mouseover="playVideo($event.currentTarget)" 
              @mouseout="pauseVideo($event.currentTarget)"
            >
              <video muted loop class="background-video">
                <source src="/images/winelands-video.mov" type="video/mp4">
                Your browser does not support the video tag.
              </video>
              <div class="container cover-panel h-100">
                <div class="row">
                  <div class="col-md-4 p-5 text-center" style="background-color: rgba(1, 1, 1, 0.5);">
                    <h2 class="switch-white"><strong>Welcome to the Winelands</strong></h2>
                    <p>Journey through vineyard-dotted valleys and historic towns to discover South Africa's most coveted real estate. The Cape Winelands offers stunning scenery, world-class food and wine, a healthy lifestyle, top-tier education, and prestigious addresses. This luxury destination is where many aspire to call home.</p>
                    <a href="/winelands" class="btn cta-btn">Read more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sectionTwo">
      <div class="container-fluid g-0">
        <div class="row g-0 switch-bg-lgrey my-5">
          <div class="col-lg-6">
            <img src="/images/home-luxuryestates.jpg" class="img img-fluid">
          </div>
          <div class="col-md-5 switch-bg-lgrey p-5 align-self-center">
            <div class="h-100 p-5 text-center">
              <h2 class="switch-blue mb-4"><strong>Luxury Estates</strong></h2>
              <p class="text-dark">Set in idyllic locations with wide-open spaces and tranquil environments, these luxury estates offer the
                perfect balance between fine living and a secure environment in which to raise a family or enjoy your
                retirement. </p>
              <p class="text-dark">Each uniquely designed estate complements the natural surroundings and provides you with the higher
                quality of life that you deserve. </p>
              <a href="/estates" class="btn cta-btn">View our estate portfolio</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sectionTwo">
      <div class="container-fluid g-0">
        <div class="row g-0 switch-bg-lgrey my-5">
          <div class="col-md-6 switch-bg-lgrey p-5 align-self-center">
            <div class="h-100 p-5 text-center">
              <h2 class="switch-blue mb-4"><strong>New Developments</strong></h2>
              <p class="text-dark">Few things excite us as much as breaking ground on a new development, where you find upmarket homes,
                state-of-the-art security, landscaped grounds, modern amenities, green technology and family-friendly
                facilities. </p>
              <p class="text-dark">These luxury new developments are architect-designed, with immaculate finishes, and enable discerning
                buyers and renters to live in their own piece of paradise.</p>
              <a href="/developments" class="btn cta-btn">View our new developments</a>
            </div>
          </div>
          <div class="col-lg-6">
            <img src="/images/home-new.jpg" class="img img-fluid">
          </div>
        </div>
      </div>
    </section>
    
    <a id="footer"></a>
    <div class="container-fluid py-3" style="background-color: #255699;">
      <div class="row p-2">
        <div class="col-md-6 p-4">
          <h2 class="text-white"><strong>Call to action</strong></h2>
          <p>Contact us at <a class="text-white" href="mailto:admin.valdevie@everitt.co.za">admin.valdevie@everitt.co.za</a> or <a class="text-white" href="tel:+27832581212">+27 83 258 1212</a> or fill out the form below.</p>
          <ContactForm />
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import FeaturedProperty from '@/components/FeaturedProperty.vue'
import ContactForm from '@/components/ContactForm.vue'
import Footer from '@/components/Footer.vue'
import Search from '@/components/Search.vue'

export default {
  components: {
    Menu, Footer, Search, FeaturedProperty, ContactForm
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {
    playVideo(element) {
      const video = element.querySelector('.background-video');
      video.play();
    },
    pauseVideo(element) {
      const video = element.querySelector('.background-video');
      video.pause();
    },
    viewFullScreen(button) {
      const video = button.closest('.video-container').querySelector('.background-video');
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) { // Safari
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) { // IE11
        video.msRequestFullscreen();
      }
    }
  }
}
</script>

<style scoped>
.video-container {
  position: relative;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

h4, p, .fullscreen-btn, .cover-panel {
  position: relative;
  z-index: 2;
  color: #fff;
}

.fullscreen-btn {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.fullscreen-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
</style>

